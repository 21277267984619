import React, { FC } from "react";
import { cva, VariantProps } from "class-variance-authority";
import "./global-loader-styles.scss";

const wrapperStyles = cva(
    "absolute left-1/2 top-1/2 flex w-max -translate-x-1/2 -translate-y-1/2 items-end justify-center space-x-1.5",
    {
        variants: {
            size: {
                md: "h-8",
                default: "h-11",
            },
        },
    },
);

const loadingBarStyles = cva("loading-bar rounded-md bg-primary", {
    variants: {
        size: {
            md: "h-1.5 w-1.5",
            default: "h-2.5 w-2.5",
        },
    },
});

interface GlobalLoaderProps extends VariantProps<typeof loadingBarStyles> {}

export const GlobalLoader: FC<GlobalLoaderProps> = ({ size = "default" }) => (
    <div className="relative h-full w-full">
        <div className={wrapperStyles({ size })}>
            {[...Array(4)].map((_, index) => (
                <div className={loadingBarStyles({ size })} key={index}></div>
            ))}
        </div>
    </div>
);
