"use client";

import { FC, PropsWithChildren } from "react";
import { setDefaultOptions } from "date-fns";

export const DateFnsProvider: FC<PropsWithChildren> = ({ children }) => {
    setDefaultOptions({ weekStartsOn: 1 });

    return children;
};
