"use client";

import React, { FC, PropsWithChildren, useEffect, useState } from "react";
import useViewModuleAccess from "~/hooks/access/useViewModuleAccess";
import { AccessModule } from "~/types/general.types";
import { usePathname, useRouter } from "~/i18n/routing";
import useCurrentUserData from "~/hooks/useCurrentUserData";
import { GlobalLoader } from "@ui/global-loader";

export const PermissionsProvider: FC<PropsWithChildren> = ({ children }) => {
    const [module, setModule] = useState<AccessModule>();
    const access = useViewModuleAccess(module);
    const pathname = usePathname();
    const [isRedirecting, setIsRedirecting] = useState(false);
    const router = useRouter();
    const { data, loading, error } = useCurrentUserData();

    const redirectToDashboard = () => {
        if (module && !access.loading && !access.allowed && pathname !== "/" && !isRedirecting) {
            router.replace("/");
            setIsRedirecting(true);
        }
    };

    const clearValues = () => {
        if (pathname === "/" && isRedirecting) {
            setIsRedirecting(false);
            setModule(undefined);
        }
    };

    useEffect(() => {
        redirectToDashboard();
        clearValues();
    }, [access, pathname, isRedirecting, module]);

    useEffect(() => {
        const moduleValue = Object.values(AccessModule).find((module) =>
            pathname.toLowerCase().includes(module.toLowerCase()),
        );
        if (moduleValue) {
            setModule(moduleValue);
        }
    }, [pathname]);

    if ((!data && !error) || loading || access.loading || isRedirecting) {
        return <GlobalLoader />;
    }

    return <>{children}</>;
};
