import(/* webpackMode: "eager" */ "/codebuild/output/src1557628688/src/web-app/app/posthog-page-view.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/codebuild/output/src1557628688/src/web-app/components/ui/toaster.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TableContextProvider"] */ "/codebuild/output/src1557628688/src/web-app/context/Table.content.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["UserContextProvider"] */ "/codebuild/output/src1557628688/src/web-app/context/User.context.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HydrationBoundary"] */ "/codebuild/output/src1557628688/src/web-app/node_modules/@tanstack/react-query/build/modern/HydrationBoundary.js");
;
import(/* webpackMode: "eager", webpackExports: ["IsRestoringProvider","useIsRestoring"] */ "/codebuild/output/src1557628688/src/web-app/node_modules/@tanstack/react-query/build/modern/isRestoring.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src1557628688/src/web-app/node_modules/@tanstack/react-query/build/modern/QueryClientProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["QueryErrorResetBoundary","useQueryErrorResetBoundary"] */ "/codebuild/output/src1557628688/src/web-app/node_modules/@tanstack/react-query/build/modern/QueryErrorResetBoundary.js");
;
import(/* webpackMode: "eager", webpackExports: ["useInfiniteQuery"] */ "/codebuild/output/src1557628688/src/web-app/node_modules/@tanstack/react-query/build/modern/useInfiniteQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useIsFetching"] */ "/codebuild/output/src1557628688/src/web-app/node_modules/@tanstack/react-query/build/modern/useIsFetching.js");
;
import(/* webpackMode: "eager", webpackExports: ["useMutation"] */ "/codebuild/output/src1557628688/src/web-app/node_modules/@tanstack/react-query/build/modern/useMutation.js");
;
import(/* webpackMode: "eager", webpackExports: ["useIsMutating","useMutationState"] */ "/codebuild/output/src1557628688/src/web-app/node_modules/@tanstack/react-query/build/modern/useMutationState.js");
;
import(/* webpackMode: "eager", webpackExports: ["useQueries"] */ "/codebuild/output/src1557628688/src/web-app/node_modules/@tanstack/react-query/build/modern/useQueries.js");
;
import(/* webpackMode: "eager", webpackExports: ["useQuery"] */ "/codebuild/output/src1557628688/src/web-app/node_modules/@tanstack/react-query/build/modern/useQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseInfiniteQuery"] */ "/codebuild/output/src1557628688/src/web-app/node_modules/@tanstack/react-query/build/modern/useSuspenseInfiniteQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseQueries"] */ "/codebuild/output/src1557628688/src/web-app/node_modules/@tanstack/react-query/build/modern/useSuspenseQueries.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseQuery"] */ "/codebuild/output/src1557628688/src/web-app/node_modules/@tanstack/react-query/build/modern/useSuspenseQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src1557628688/src/web-app/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src1557628688/src/web-app/node_modules/next-intl/dist/esm/navigation/shared/LegacyBaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src1557628688/src/web-app/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src1557628688/src/web-app/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src1557628688/src/web-app/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src1557628688/src/web-app/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Roboto\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"300\",\"400\",\"500\",\"700\"]}],\"variableName\":\"font\"}");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src1557628688/src/web-app/providers/apollo-wrapper.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src1557628688/src/web-app/providers/date-fns-provider.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src1557628688/src/web-app/providers/permissions-provider.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src1557628688/src/web-app/providers/posthog.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src1557628688/src/web-app/providers/react-query-wrapper.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src1557628688/src/web-app/providers/session-wrapper.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src1557628688/src/web-app/styles/animations.scss");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src1557628688/src/web-app/styles/globals.scss");
